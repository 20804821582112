import React from 'react';
import { ApiService } from '../../services/ApiService';
import { AxiosError, AxiosResponse } from 'axios';
import Spinner from '../shared/Spinner';
import { DebouncedFunc, debounce } from 'lodash';
import PricingReportSelector from './PricingReportSelector';
import PricingReportTable from './PricingReportTable';

interface Props {
  valuations: { [key: string]: any }[];
  report_configs: { id: number, name: string, dimensions: string[], measures: string[], subtotals: string[], dimension_name_overrides: { [key: string]: any } }[];
  dimensions: { [key: string]: any };
  measures: { [key: string]: any };
}

interface State {
  records: { [key: string]: any }[];
  columns: string[];
  selected_valuation: { [key: string]: any } | null;
  selected_report_config: { [key: string]: any } | null;
  selected_flagship_report: { [key: string]: any } | null;
  loaded: boolean;
  downloading: boolean;
  report_emailed: boolean;
  total_pages: number | null;
  current_page: number | null;
  next_page: number | null;
  paginated_data_loaded: boolean;
  error: any;
  reports_processed: boolean;
}

class PricingReport extends React.PureComponent<Props, State> {
  private debouncedResizeTable: DebouncedFunc<() => void>
  private observer: IntersectionObserver | null;
  private lastItemRef: React.RefObject<HTMLTableRowElement>
  
  _isMounted = false;

  constructor(props: Props) {
    super(props);

    this.state = {
      records: [],
      columns: [],
      selected_valuation: this.props.valuations[0],
      selected_report_config: this.props.report_configs[0],
      selected_flagship_report: null,
      loaded: false,
      downloading: false,
      report_emailed: false,
      current_page: null,
      next_page: null,
      total_pages: null,
      paginated_data_loaded: false,
      error: null,
      reports_processed: false };

    this.debouncedResizeTable = debounce(this.resizeTable, 200);
    this.observer = null;
    this.lastItemRef = React.createRef<HTMLTableRowElement>();
  }

  componentDidMount = () => {
    this._isMounted = true;
    window.addEventListener('resize', this.debouncedResizeTable)
    this.resizeTable();
    this.getValuationReportSet();
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('resize', this.debouncedResizeTable)
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  render() {
    return (
      <div>
        <PricingReportSelector
          downloading={this.state.downloading}
          downloadReport={this.downloadReport}
          loaded={this.state.loaded}
          records={this.state.records}
          reportEmailed={this.state.report_emailed}
          reportConfigs={this.props.report_configs}
          selectedReportConfig={this.state.selected_report_config}
          selectedValuation={this.state.selected_valuation}
          selectReport={this.selectReport}
          selectValuation={this.selectValuation}
          valuations={this.props.valuations}
        />

        { this.state.loaded ? (
          <PricingReportTable
            columns={this.state.columns}
            dimensions={this.props.dimensions}
            error={this.state.error}
            lastItemRef={this.lastItemRef}
            measures={this.props.measures}
            paginatedDataLoaded={this.state.paginated_data_loaded}
            records={this.state.records}
            selectedReportConfig={this.state.selected_report_config}
            reportsProcessed={this.state.reports_processed}
            valuations={this.props.valuations}
            reportConfigs={this.props.report_configs}
          />
        ) : (
          <div className="d-flex justify-content-center align-items-center h-100 w-100 mt-50 mb-5">
            <Spinner width="6rem" height="6rem" />
          </div>
        ) }

      </div>
    )
  }

  private getValuationReportSet = () => {
    if (!this.state.selected_valuation || !this.state.selected_report_config) {
      this.setState({ loaded: true });
      return;
    }

    this.setState({
      records: []
    }, () => {
      ApiService.get(`/api/valuations/${this.state?.selected_valuation?.id}/flagship_report_sets`, {}, true)
        .then((response: AxiosResponse) => {
          if (this._isMounted) {
            const reportSet = response.data;
            this.setState({ reports_processed: reportSet?.status === 'completed' }, this.getValuationReportConfigFlagshipReport);
          }
        })
        .catch((error: AxiosError) => {
          if (this._isMounted) {
            this.setState({ loaded: true, error: error });
          }
        });
    });
  }

  private getValuationReportConfigFlagshipReport = () => {
    this.setState({
      records: []
    }, () => {
      if (this._isMounted) {
        if (!this.state.reports_processed) {
          this.setState({ loaded: true });
        } else {
          ApiService.get(`/api/valuations/${this.state?.selected_valuation?.id}/report_configs/${this.state?.selected_report_config?.id}/flagship_reports`, {}, true)
            .then((response: AxiosResponse) => {
              if (this._isMounted) {
                const flagshipReport = response.data;
                this.setState({
                  selected_flagship_report: flagshipReport
                }, this.getValuationFlagshipReportFlagshipReportRecords);
              }
            })
            .catch((error: AxiosError) => {
              if (this._isMounted) {
                this.setState({ loaded: true, error: error });
              }
            });
        }
      }
    });
  }

  private getValuationFlagshipReportFlagshipReportRecords = () => {
    if (!this.state.selected_flagship_report) {
      this.setState({ loaded: true });
    } else {
      if (this.state.paginated_data_loaded) return;
      const page = this.state.next_page ? this.state.next_page : 1;

      ApiService.get(`/api/valuations/${this.state?.selected_valuation?.id}/flagship_reports/${this.state?.selected_flagship_report?.id}/flagship_report_records`, { page: page }, true)
        .then((response: AxiosResponse) => {
          const paginatedDataLoaded = response.data.current_page === response.data.total_pages;
          const nextPage = paginatedDataLoaded ? null : response.data.current_page + 1;
          
          if (this._isMounted) {
            this.setState(prevState => ({
              records: [...prevState.records, ...response.data.records],
              columns: this.state.selected_report_config ? this.state.selected_report_config['dimensions'].concat(this.state.selected_report_config['measures']) : [],
              current_page: response.data.current_page,
              next_page: nextPage,
              total_pages: response.data.total_pages,
              paginated_data_loaded: paginatedDataLoaded,
              loaded: true
            }), this.observeLastItem);
          }
        })
        .catch((error: AxiosError) => {
          if (this._isMounted) {
            this.setState({ loaded: true, error: error });
          }
        });
    }
  }

  private downloadReport = () => {
    this.setState({ downloading: true }, () => {
      ApiService.get(`/api/valuations/${this.state?.selected_valuation?.id}/flagship_reports/${this.state?.selected_flagship_report?.id}/flagship_report_attachments/url`, {}, true)
      .then((response: AxiosResponse) => {
        if (this._isMounted) {
          const fileUrl = response.data.url;
          const link = document.createElement('a');
          link.href = fileUrl;
          link.download = '';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          this.setState({ downloading: false });
        }
      })
      .catch((error: AxiosError) => {
        if (this._isMounted) {
          this.setState({ loaded: true, error: error, downloading: false });
        }
      });
    });
  }

  private observeLastItem = () => {
    if (this.observer) {
      this.observer.disconnect();
    }

    const { records } = this.state;
    const lastElement = this.lastItemRef.current;
    
    if (!lastElement || records.length < 5) return;

    this.observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        this.getValuationFlagshipReportFlagshipReportRecords();
      }
    }, {
      root: document.querySelector('#pricing-report-table-container'),
      rootMargin: '0px',
      threshold: 0
    });

    if (lastElement) this.observer.observe(lastElement);
  }

  private selectValuation = (val: { [key: string]: any }) => {
    this.setState({
      loaded: false,
      selected_valuation: val,
      report_emailed: false,
      current_page: null,
      next_page: null,
      total_pages: null,
      paginated_data_loaded: false,
    }, this.getValuationReportSet)
  }

  private selectReport = (config: any) => {
    this.setState({
      selected_report_config: config,
      current_page: null,
      next_page: null,
      total_pages: null,
      paginated_data_loaded: false,
      loaded: false
    }, this.getValuationReportConfigFlagshipReport);
  }

  private resizeTable = () => {
    document.documentElement.style.setProperty('--flagship-report-table-height', `${window.innerHeight - 250}px`)
  }

}

export default PricingReport;
